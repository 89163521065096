// .ant-collapse {
//   background-color: var(--card-background);
//   border: 1px solid var(--color-border);
// }
// .ant-collapse > .ant-collapse-item {
//   border-bottom: 1px solid var(--color-border) !important;
// }
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  color: var(--filterOption-text) !important;
  font-weight: 500;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-arrow {
  font-size: 15px;
  font-weight: 600;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-header-text{
  color: var(--filterOption-text);
  font-size: 15px;
  font-weight: 500;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header{
  padding: 0;
}
// .ant-collapse-header {
//   display: flex;
//   align-items: center !important;
// }
// .ant-collapse .ant-collapse-content {
//   background-color: transparent !important;
//   border-top: 1px solid var(--color-border) !important;
// }

//   .ant-table-wrapper .ant-table{
//     background-color: transparent;
//   }
//   .ant-table-wrapper .ant-table-thead >tr>th{
//     background-color: transparent;
//     color: var(--color-text-secondary);
//     font-size: 15px;
//   }

//   .ant-table-cell{
//     color: var(--color-text-secondary);
//     font-size: 15px;
//     font-weight: 400;
//   }
//   .ant-table-cell-row-hover{
//   background-color: transparent !important;
//   }

.ant-table-wrapper .ant-table-thead > tr > th::before {
  display: none;
}

//disable input value css
.ant-input-outlined.ant-input-disabled,
:where(.css-dev-only-do-not-override-1ne6r1s).ant-input-outlined[disabled] {
  color: var(--color-text-secondary);
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name{
  color: var(--color-text-primary);
}
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item{
  border: 1px solid var(--color-border);
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon{
  color: var(--color-text-primary);
}

// .ant-dropdown .ant-dropdown-menu {
//   background-color: var(--color-text-secondary) !important;
//   color: var(--color-text-primary);
// }

.ant-slider .ant-slider-rail{
  background-color: #8A8A8A;
}
.ant-slider:hover .ant-slider-rail{
  background-color: #939292;
}

.ant-table-wrapper .ant-table-thead >tr>th{
  // background: #14365E;
  // color: white;
  font-size: 12px;
  font-family: Montserrat-SemiBold;
}
// .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child{
//   border-start-start-radius: 0;
// }
// .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child{
//   border-start-end-radius: 0 ;
// }
.ant-table-wrapper .ant-table-tbody >tr >td{
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.tags-of-project{
  .ant-select-selection-placeholder{
    padding-left: 10px !important;
  }
  
}
.ant-select-selector{
  color: var(--color-text-secondary) !important;
}
.ant-select-outlined.ant-select-multiple .ant-select-selection-item{
  background-color: var(--grey-button) !important;
  color: white ;
}
.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-remove{
  color: white;
}


// pagination arrow css
.ant-pagination .ant-pagination-next .ant-pagination-item-link{
  color: var(--color-text-primary);
}
.ant-pagination .ant-pagination-prev .ant-pagination-item-link{
  color: var(--color-text-primary);
}
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover{
  background-color: var(--color-box-secondary)
}

.ant-pagination .ant-pagination-item {
  background-color: var(--color-box-secondary);
}
.ant-pagination .ant-pagination-item a{
  color: var(--color-text-primary);
}

.ant-tabs-tab{
  font-size: 15px !important;
  // color: var(--color-text-primary) !important;
  font-weight: 500;
}
.ant-tabs-tab-active{
  font-size: 15px !important;
  // color: var(--color-primary) !important;
  font-weight: 500;
}
.ant-tabs >.ant-tabs-nav .ant-tabs-nav-list{
  column-gap: 7px;
}
.ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab{
  border-radius: 0;
}
.ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab-active{
  border-color: #1778F2;
  border-radius: 10px;
}
.ant-tabs-nav-wrap{
  background: var(--color-box-secondary);
  border-radius: 10px;
  padding: 10px 0px 10px 10px;
  border: 1px solid var(--color-border);
}
.ant-tabs-top >.ant-tabs-nav::before{
  border: none;
}
.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab{
  transition: all 0.3s ease-in;
}