.ant-layout {
  font-family: "Poppins", sans-serif !important;

  .ant-layout-sider {
    transition: all 0.3s ease 0s;
  }

  .ant-menu {
    background-color: transparent;
  }
  .ant-menu-item {
    font-size: 16px !important;
    color: #a9b5c4 !important;
    .ant-menu-item-icon {
      min-width: 20px !important;
    }
  }

  .ant-menu-item-selected {
    font-weight: 600;
    color: white !important;
    background-color: var(--color-active-tab);

    .ant-menu-item-icon {
      min-width: 20px !important;
    }
  }
}

.side-menu-light {
  background-color: var(--color-sidebar) !important;
  .ant-menu-item-icon {
    // font-weight: 600;
    // min-width: 18px !important;
  }
  // .sidebar-active {
  //   color: rgba(0, 0, 0, 0.8);
  //   font-weight: 600;
  // }
}

.ant-breadcrumb-separator {
  font-size: 16px;
}

// .ant-popover-arrow {
//   display: none !important;
// }
.ant-popover {
  border: 1px solid var(--color-border);
  // box-shadow: 0px 10px 15px -3px var(--box-shadow);
  border-radius: 10px;
  .ant-popover-inner {
    box-shadow: none;
    background-color: var(--color-bg-secondary);
  }
}
.ant-breadcrumb .ant-breadcrumb-separator {
  color: var(--color-text-primary);
}
.ant-input::placeholder {
  color: var(--grey-button);
}

.ant-input-outlined:focus-within {
  background-color: transparent;
}

.ant-form-item .ant-form-item-label > label {
  color: var(--color-text-primary) !important;
}
.ant-select-selection-placeholder {
  color: var(--grey-button) !important;
}
.ant-select-multiple .ant-select-selection-placeholder {
  inset-inline-start: 0px;
  inset-inline-end: 0px;
  padding-left: 40px;
}

.tag-select {
  display: none !important;
}
.remove-arrow {
  .ant-select-arrow {
    display: none;
  }
}

.ant-modal {
  // z-index: 999 !important;
  .ant-modal-content {
    border: 1px solid var(--color-border);
    background-color: var(--color-bg-secondary);
  }
}

.ant-modal-close {
  color: var(--color-text-primary) !important;
}

.ant-select-selector {
  border: 1px solid var(--color-border) !important;
  background-color: transparent !important;
  padding: 5px 0 !important;
}

// result css
.ant-result-title {
  color: var(--color-text-secondary) !important;
}

.ant-popover {
  z-index: 999 !important;
}

// select css
.custom-search {
  .ant-select-selector {
    background: transparent !important;
    border: 1px solid var(--color-border) !important;
    padding: 8px 8px !important;
  }
  .ant-select-selection-placeholder {
    color: var(--grey-button);
    font-size: 16px;
  }
  .ant-select-arrow {
    color: var(--color-text-secondary) !important;
  }
  .ant-select-single {
    height: auto !important;
  }
}
.ant-select-dropdown {
  box-shadow: none !important;
  background-color: var(--color-bg-secondary);
  border: radius 0px;
}
.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}

.select-with-prefix {
  position: relative;
  .ant-select-selector {
    color: var(--color-text-secondary);
  }
  .ant-select-outlined.ant-select-multiple .ant-select-selection-item {
    background-color: var(--grey-button);
    color: white;
  }
  .ant-select-multiple
    .ant-select-selection-overflow
    .ant-select-selection-item-remove {
    color: white;
  }
}
.select-with-prefix::before {
  content: "";
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px; /* Adjust width as per your image */
  height: 15px; /* Adjust height as per your image */
  background-image: url("../img/tagIcon.svg"); /* Replace with your image URL */
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none;
}
.select-with-prefix .ant-select-selector {
  padding-left: 34px !important; /* Adjust based on image size */
}

/* Custom scrollbar styles */
.custom-scrollbar {
  overflow: auto;
  overflow-x: hidden;
  cursor: pointer;
}

/* The scrollbar itself */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* The track (progress bar) */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 10px;
}

/* The draggable part of the scrollbar */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the draggable part */
  border-radius: 10px; /* Rounded corners of the draggable part */
}

/* On hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the draggable part on hover */
}

.ant-table-wrapper .ant-table {
  scrollbar-color: auto !important;
  ::-webkit-scrollbar {
    width: 6px;
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb) !important;
    border-radius: 3px;
    transition: background-color 0.3s;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track) !important;
  }
}

.custom-select {
  .ant-select-selector {
    padding: 0 11px !important;
  }
  .ant-select-selection-search-input{
    color: var(--color-text-primary) !important;
  }
  .ant-select-single .ant-select-selector{
    color: var(--color-text-primary) !important;
  }
   .ant-select-arrow{
    color: var(--grey-button) !important;
  }
}
.ant-select-dropdown .ant-select-item-option {
  color: var(--color-text-primary);
}

.custom-select .ant-select-selection-placeholder {
  padding-left: 20px !important;
}
