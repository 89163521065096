:root {
  --color-sidebar: #14365e; /* Sidebar Color */
  --color-active-tab: #0c4682; /* Sidebar Color */
  --color-primary: #1778f2; /* Primary Color */
  --color-bg-primary: #f8f8fa; /* BG Color */
  --color-bg-secondary: #ffffff; /* BG Color */
  --color-text-primary: #000000; /* Text Color */
  --color-text-secondary: #393939; /* Text Color */
  --color-text-third: #fff; /* Text Color */
  --color-box: #ffffff; /* Box Color */
  --color-box-secondary: #ffffff;
  --color-list-bg: #DEEAF4;
  --bg-background: #f8f8fa;
  --card-background: #ffffff;
  --color-border: #d0d7df;
  --card-border: #d0d7df;
  --card-blue-border: #CCDEFA;
  --card-orange-border: #FAD7CC;
  --card-pink-border: #FACCED;
  --card-red-border: #FACCCC;
  --card-green-border: #aae2a2;
  --card-grey-border: #CCCCCC;
  --card-purple-border: #D2CCFA;
  --grey-button: #707070;
  --box-shadow:rgba(0,0,0,0.1);
  --filterOption-text:#6A6A6A;
  --table-border:#AEBBCC;
  --scrollbar-thumb: #BFBFBF;
  --scrollbar-track:#EDEDED;
}

/* Dark theme variables */
.dark {
  --color-sidebar: #151515; /* Sidebar Color */
  --color-active-tab: #152c45; /* Sidebar Color */
  --color-primary: #1778f2; /* Primary Color */
  --color-bg-primary: #191919; /* BG Color */
  --color-bg-secondary: #191919; /* BG Color */
  --color-text-primary: #ffffff; /* Text Color */
  --color-text-secondary: #ffffff; /* Text Color */
  --color-text-third: #000; /* Text Color */
  --color-box: #202020; /* Box Color */
  --color-box-secondary: #111111; /* Box Color 2 */
  --color-list-bg: #00070f;
  --bg-background: #191919;
  --color-border: #494949;
  --card-background: #202020;
  --card-border: #313131;
  --card-blue-border: #1F395C;
  --card-orange-border: #511F0F;
  --card-pink-border: #5C1F35;
  --card-red-border: #5C1F1F;
  --card-green-border: #324e2d;
  --card-grey-border: #494949;
  --card-purple-border: #331F5C;
  --grey-button: #424242;
  --box-shadow:#494949;
  --filterOption-text:#fff;
  --table-border:#081525;
  --scrollbar-thumb:#7E7E7E;
  --scrollbar-track:#2E2E2E;
}


