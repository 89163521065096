@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .delete-btn {
    @apply bg-transparent text-[#1778f2] hover:text-[#1778f2] md:text-sm text-xs hover:bg-transparent border border-[#1778f2] rounded-[6px] font-montserratMedium tracking-wider !important;
  }
}

@layer utilities {
  .eol-scroll {
    @apply w-full px-3 overflow-y-auto
    [&::-webkit-scrollbar]:w-2
    [&::-webkit-scrollbar-track]:rounded-full
    [&::-webkit-scrollbar-track]:bg-gray-100
    [&::-webkit-scrollbar-thumb]:rounded-full
    [&::-webkit-scrollbar-thumb]:bg-gray-300
    dark:[&::-webkit-scrollbar-track]:bg-neutral-700
    dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500;
  }
}

@layer components {
  .eol-height {
    min-height: calc(100dvh - 150px);
    max-height: calc(100dvh - 150px);
  }
}

.eolModalView { max-height: calc(100vh - 150px) }
.gitOrgTemplate-height { max-height: calc(100vh - 250px); min-height: calc(100dvh - 500px); }

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

/* Montserrat-Medium */
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

/* Montserrat-Semibold */
@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

.ant-select-selection-item{
  padding-left: 10px !important;
}

.add-project-filtype-dropdown{
  border: 1px solid #ced0d5;
}

@media (min-width: 992px) {
  :where(.css-dev-only-do-not-override-17h4ymj).ant-col-lg-4 {
      flex: 0 0 19.666667% !important;
      max-width: 19.666667% !important;
  }
}

.versioCheck {
  display: flex  ;
  justify-content: space-between;
  align-items: center;
}

.ptop-2 { padding-top: 2px !important; }

 .extraControlclass .ant-tabs-nav-wrap {
    border-bottom-right-radius: 0px !important;
    border-right: 0px !important;
    border-top-right-radius: 0px !important;
}

.ant-tabs-extra-content{
  background: var(--color-box-secondary);
  border-radius: 10px;
  padding: 20px 20px 20px 10px;
  border: 1px solid var(--color-border);
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: 0px !important;
}

.filterContainer{
  min-width: 250px;
  z-index: 999;
  right: 5px;
  top: 35px;
}

.font15{
  font-size: 15px;
}

.ant-table {
  table-layout: auto !important;
}

.ant-table-row{
  font-size: 14px;
  line-height: 20px;
}

.ant-table-empty .ant-table-body{
  overflow: hidden !important;
}
.ant-collapse-item{
  background: none !important;
}

.wrapper .gitOrgTemplate {
  /* overflow: hidden; */
  display: grid;
  grid-template-rows: 5px;
  grid-auto-rows: 5px;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  row-gap: 2.5rem;
  column-gap: 1rem;
}

.gitOrgTemplate .card {
  scroll-snap-align: start;
  list-style: none;
  background: #f0ecec;
  cursor: pointer;
  flex-direction: column;
  height: fit-content;
  padding: 5px 10px;
}

.ant-radio-group .ant-radio-wrapper {
  background-color: #ebecf0;
  width: 100%;
  padding: 10px;
}

.dec-card-body .ant-card-body {
  padding: 5px !important;
  min-height: 100px;
}

.highlight {
  background-color: yellow; 
  font-weight: bold;
}

.card_del_icon {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card_wrapper:hover .card_del_icon {
  visibility: visible;
  opacity: 1;
}

.card_delete_container{
    display: flex;
    justify-content: flex-end;
    background-color: #f0ecec;
    padding: 5px;
}

.fade-in-image {
  opacity: 0;
  transform: scale(0.9);
  animation: fadeInScale 2s ease-out forwards;
}

@keyframes fadeInScale {
  0% {
      opacity: 0;
      transform: scale(0.9);
  }
  100% {
      opacity: 1;
      transform: scale(1);
  }
}