/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.wrapper .carousel {
  overflow: hidden;
  display: grid;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  /* grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 30px;
}

.carousel :where(.card, .img) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .card {
  scroll-snap-align: start;
  list-style: none;
  background: #f0ecec;
  cursor: pointer;
  flex-direction: column;
  height: 185px;
  /* border-radius: 8px; */
  /* padding: 10px 15px;  */
}

.carousel .card .img {
  background: #ffffff;
  height: 65px; /* Adjusted image height */
  width: 65px;  /* Adjusted image width */
  border-radius: 50%;
  margin-bottom: 10px; /* Added margin to give space between image and text */
}

.card .img img {
  width: 65px; /* Adjusted image size */
  height: 65px; /* Adjusted image size */
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}

.carousel .card h2 {
  font-weight: 500;
  font-size: 14px; /* Reduced font size */
  margin: 5px 0 5px; /* Reduced margin to fit in the smaller card */
  text-align: center;
}

.carousel .card span {
  /* color: #19ecff; */
  font-size: 12px; /* Reduced font size */
}

